export enum Employed {
  NO = "no",
  YES = "yes",
}

export enum Experience {
  JUNIOR = "junior",
  MID = "mid",
  SENIOR = "senior",
}

export enum Availability {
  FULL_TIME = "fullTime",
  PART_TIME = "partTime",
}
